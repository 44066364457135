import * as portalActionTypes from "./actionTypes/portalActionTypes";
import {axiosInstance} from "../../Axios";
import _ from "lodash";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import {MySwal, Toast} from "../../SWAL";
import {
  BACKUP_PORTAL_FAIL,
  BACKUP_PORTAL_START, BACKUP_PORTAL_SUCCESS,
  GET_ALL_PROJECTS_LIST_FAIL,
  GET_ALL_PROJECTS_LIST_START,
  GET_ALL_PROJECTS_LIST_SUCCESS
} from "./actionTypes/portalActionTypes";

export const detectPortalStart = () => {
  return {
    type: portalActionTypes.DETECT_PORTAL_START,
  }
};

export const detectPortalFail = () => {
  LocalStorageWrapped.removeItem("portalInfo");
  return {
    type: portalActionTypes.DETECT_PORTAL_FAIL,
  }
};

export const detectPortalSuccess = (portalName: string, found: boolean, portalType: string, id: string, slug?: string) => {
  return {
    type: portalActionTypes.DETECT_PORTAL_SUCCESS,
    portalName,
    found,
    portalType,
    id,
    slug
  }
};

export const getPortalStart = () => {
  return {
    type: portalActionTypes.GET_PORTAL_START
  };
}

export const getPortalFail = () => {
  return {
    type: portalActionTypes.GET_PORTAL_FAIL
  };
}

export const getPortalSuccess = (portal) => {
  return {
    type: portalActionTypes.GET_PORTAL_SUCCESS,
    portalName: portal.name,
    portalSlug: portal.slug,
    portalId: portal.id,
    comments: portal.comments,
    customScripts: portal.customScripts,
    version: portal.version
  };
}

export const detectPortal = (location: Location) => {
  return (dispatch: any) => {
    dispatch(detectPortalStart());
    let path = location.pathname;
    let pathList = path.split("/");
    let subdomain = location.hostname.split(".");
    let portalName = null;
    let portalType = "url";
    if (pathList.length > 2) {
      portalName = pathList[1];
    } else if (subdomain.length > 1) {
      portalName = subdomain[0];
      portalType = "subdomain";
    }
    const portalInfo = LocalStorageWrapped.getItem("portalInfo");
    let portalNameFromLocal = portalInfo.portalName;
    if (portalName === portalNameFromLocal) {
      dispatch(detectPortalSuccess(portalNameFromLocal, true, portalInfo.portalType,
        portalInfo.portalId));
      return;
    }

    if (!portalName) {
      dispatch(detectPortalFail());
      return;
    }
    axiosInstance("portal/verify", 'post', {
      'Content-Type': 'application/json',
    }, JSON.stringify({portalName}))
      .then(res => {
        let response = _.get(res, "data");
        if (response.success) {
          const portalInfo = {
            portalName: response.object.name,
            portalId: response.object.id,
            portalType: portalType,
            portalSlug: response.object.slug,
            version: response.object.version,
            createdAt: response.object.createdAt,
            id: response.object.id,
            name: response.object.name,
            slug: response.object.slug,
            customScripts: response.object.customScripts,
          };
          LocalStorageWrapped.setItem("portalInfo", portalInfo);
          dispatch(detectPortalSuccess(response.object.name, true, portalType, response.object._id));
        }
      })
      .catch(err => {
        dispatch(detectPortalFail());
      })
  }
};

const create_portal_start = () => {
  return {
    type: portalActionTypes.CREATE_PORTAL_START,
  };
};

const create_portal_success = (success: boolean, message: string, createdAt: string, id: string, name: string, slug: string, customScripts: string, version: string, comments: string) => {
  return {
    type: portalActionTypes.CREATE_PORTAL_SUCCESS,
    success: success,
    message: message,
    createdAt: createdAt,
    comments: comments,
    id: id,
    name: name,
    slug: slug,
    customScripts: customScripts,
    version: version
  };
};

const create_portal_failure = (errors: object) => {
  return {
    type: portalActionTypes.CREATE_PORTAL_FAILURE,
    errors: errors,
  }
};

export const create_portal = (portalDetails: any, createCopy: boolean) => {
  const data = new FormData();
  data.set("name", portalDetails.name);
  data.set("companyLogo", portalDetails.image);
  data.set("customScripts", portalDetails.customScripts);
  data.set("comments", portalDetails.comments);
  data.set("portalId", portalDetails.portalId);

  return async dispatch => {
    dispatch(create_portal_start());
    let url;
    if(createCopy){
      url ='portal/create-copy';
    }else{
      url = 'portal/create';
    }
    axiosInstance(url, 'post',
      { 'Content-Type': 'application/x-www-form-urlencoded'}, data
    ).then(response => {
      let res = _.get(response, "data");
      if (res.success) {
        const response = res.object;
        if (res){
          Toast.fire({ icon: "success", titleText: portalDetails.name + " had been created"});
          dispatch(create_portal_success(res.success, response.message, response.createdAt, response.id, response.name, response.slug, response.customScripts, response.version, response.comments));
          dispatch(get_portal_list());
        }
      }
    }).catch(err => {
      if(err && err.response) {
        Toast.fire({ icon: "error", titleText: err.response.data.errors});
        dispatch(create_portal_failure(err.response.data.errors));
      }

    });
  }
};

const get_portal_list_start = () => {
  return {
    type: portalActionTypes.GET_PORTAL_LIST_START,
  }
};

const get_portal_list_success = (success: boolean, portal_list: []) => {
  return {
    type: portalActionTypes.GET_PORTAL_LIST_SUCCESS,
    success: success,
    portal_list: portal_list,
  }
};

const get_portal_list_fail = (success: boolean, errors: []) => {
  return {
    type: portalActionTypes.GET_PORTAL_LIST_FAIL,
    success: success,
    errors: errors,
  }
};


const get_portal_group_list_start = () => {
  return {
    type: portalActionTypes.GET_PORTAL_GROUP_LIST_START,
  }
};



const get_all_datasets_list_start = () => {
  return {
    type: portalActionTypes.GET_ALL_DATASETS_LIST_START,
  }

}


const get_all_datasets_list_success = (success: boolean, dataset_list: []) => {
  return {
    type: portalActionTypes.GET_ALL_DATASETS_LIST_SUCCESS,
    success: success,
    dataset_list: dataset_list,
  }
};

const get_all_datasets_list_fail = (success: boolean, errors: []) => {
  return {
    type: portalActionTypes.GET_ALL_DATASETS_LIST_FAIL,
    success: success,
    errors: errors,
  }
};


const get_all_users_list_start = () => {
  return {
    type: portalActionTypes.GET_ALL_USERS_LIST_START,
  }

}

const get_all_users_list_success = (success: boolean, user_list: []) => {
  return {
    type: portalActionTypes.GET_ALL_USERS_LIST_SUCCESS,
    success: success,
    user_list: user_list,
  }
};

const get_all_users_list_fail = (success: boolean, errors: []) => {
  return {
    type: portalActionTypes.GET_ALL_USERS__LIST_FAIL,
    success: success,
    errors: errors,
  }
};


const get_all_projects_list_start = () => {
  return {
    type: portalActionTypes.GET_ALL_PROJECTS_LIST_START,
  }

}


const get_all_projects_list_success = (success: boolean, project_list: []) => {
  return {
    type: portalActionTypes.GET_ALL_PROJECTS_LIST_SUCCESS,
    success: success,
    project_list: project_list,
  }
};

const get_all_projects_list_fail = (success: boolean, errors: []) => {
  return {
    type: portalActionTypes.GET_ALL_PROJECTS_LIST_FAIL,
    success: success,
    errors: errors,
  }
};


const get_portal_group_list_success = (success: boolean, group_list: []) => {
  return {
    type: portalActionTypes.GET_PORTAL_GROUP_LIST_SUCCESS,
    success: success,
    group_list: group_list,
  }
};

const get_portal_group_list_fail = (success: boolean, errors: []) => {
  return {
    type: portalActionTypes.GET_PORTAL_GROUP_LIST_FAIL,
    success: success,
    errors: errors,
  }
};


export const remove_selected_portal = () => {
  return {
    type: portalActionTypes.CLEAR_SELECTED_PORTAL,
  }
};


export const get_portal_list = () => {
  return dispatch => {
    dispatch(get_portal_list_start());
    axiosInstance("portal/accessible-list", "get", {}).then(response => {
      let res = _.get(response, "data");
      const portal_list = res.result;
      const success = res.success;
      if (success) {
        dispatch(get_portal_list_success(success, portal_list));
      }
    }).catch(err => {
      if(err && err.response){
          const errors = err.response.data.errors;
          dispatch(get_portal_list_fail(false, errors));
      }
    });
  }
};


export const get_portal_group_list = () => {
  return dispatch => {
    dispatch(get_portal_group_list_start());
    axiosInstance("portal/portal-groups-list", "get", {}).then(response => {
      let res = _.get(response, "data");
      const portal_list = res.result;
      const success = res.success;
      if (success) {
        dispatch(get_portal_group_list_success(success, portal_list));
      }
    }).catch(err => {
      if(err && err.response){
        const errors = err.response.data.errors;
        dispatch(get_portal_group_list_fail(false, errors));
      }
    });
  }
};


export const get_all_users_list = () => {
  return dispatch => {
    dispatch(get_all_users_list_start());
    axiosInstance("users/all-portals", "get", {}).then(response => {
      let res = _.get(response, "data");
      const users_list = res.result;
      const success = res.success;
      if (success) {
        dispatch(get_all_users_list_success(success, users_list));
      }
    }).catch(err => {
      if(err && err.response){
        const errors = err.response.data.errors;
        dispatch(get_all_users_list_fail(false, errors));
      }
    });
  }
};



export const get_all_datasets_list = () => {
  return dispatch => {
    dispatch(get_all_datasets_list_start());
    axiosInstance("users/all-datasets", "get", {}).then(response => {
      let res = _.get(response, "data");
      const datasets_list = res.result;
      const success = res.success;
      if (success) {
        dispatch(get_all_datasets_list_success(success, datasets_list));
      }
    }).catch(err => {
      if(err && err.response){
        const errors = err.response.data.errors;
        dispatch(get_all_datasets_list_fail(false, errors));
      }
    });
  }
};


export const get_all_projects_list = () => {
  return dispatch => {
    dispatch(get_all_projects_list_start());
    axiosInstance("users/all-projects", "get", {}).then(response => {
      let res = _.get(response, "data");
      const projects_list = res.result;
      const success = res.success;
      if (success) {
        dispatch(get_all_projects_list_success(success, projects_list));
      }
    }).catch(err => {
      if(err && err.response){
        const errors = err.response.data.errors;
        dispatch(get_all_projects_list_fail(false, errors));
      }
    });
  }
};

export const clearPortalState = () => {
  return {
    type: portalActionTypes.CLEAR_PORTAL_STATE,
  }
};

const get_portal_admin_list_start = () => {
  return {
    type: portalActionTypes.GET_PORTAL_ADMIN_LIST_START,
  }
};

const get_portal_admin_list_success = (success: boolean, portal_admin_list: []) => {
  return {
    type: portalActionTypes.GET_PORTAL_ADMIN_LIST_SUCCESS,
    get_portal_admin_list_success: success,
    portal_admin_list: portal_admin_list,
  }
};

const get_portal_admin_list_fail = (success: boolean, errors: []) => {
  return {
    type: portalActionTypes.GET_PORTAL_ADMIN_LIST_FAIL,
    get_portal_admin_list_success: success,
    get_portal_admin_list_errors: errors,
  }
};

export const changeSelectedPortal = (portal: object) => {
  return dispatch => {
    dispatch({
      type: portalActionTypes.CHANGE_SELECTED_PORTAL,
      portal: portal
    })
  }
};


export const set_portal_selected = (portal: object) => {
  return dispatch => {
    dispatch({
      type: portalActionTypes.SET_SELECTED_PORTAL,
      portal: portal
    })
  }
};

export const setSelectedPortal = () => {
  return dispatch => {
    dispatch({
      type: portalActionTypes.CHANGE_SELECTED_PORTAL,
      portal: LocalStorageWrapped.getItem('selectedPortal')
    })
  }
};

export const get_portal_admin_list = (slug: string) => {
  return dispatch => {
    dispatch(get_portal_admin_list_start());
    axiosInstance(`${slug}/admins`, "get", {})
      .then(
        response => {
          let res = _.get(response, "data");
          const portal_admin_list = res.result;
          const success = res.success;
          if (success) {
            dispatch(get_portal_admin_list_success(success, portal_admin_list));
          }
        }).catch(err => {
          if(err && err.response) {
              const errors = err.response.data.errors;
              dispatch(get_portal_admin_list_fail(false, errors));
          }
    });
  }
};

const deactivatePortalStart = () => {
  return {
    type: portalActionTypes.DEACTIVATE_PORTAL_START
  }
};

const deactivatePortalSuccess = (response: string) => {
  return {
    type: portalActionTypes.DEACTIVATE_PORTAL_SUCCESS
  }
};

const deactivatePortalFail = (errResponse: string) => {
  return {
    type: portalActionTypes.DEACTIVATE_PORTAL_FAIL,
  }
};

const activatePortalStart = () => {
  return {
    type: portalActionTypes.ACTIVATE_PORTAL_START
  }
};

const activatePortalSuccess = (response: string) => {
  return {
    type: portalActionTypes.ACTIVATE_PORTAL_SUCCESS
  }
};

const activatePortalFail = (errResponse: string) => {
  return {
    type: portalActionTypes.ACTIVATE_PORTAL_FAIL,
  }
};


const portalBackupStart = () => {
  return {
    type: portalActionTypes.BACKUP_PORTAL_START
  }
};

const portalBackupSuccess = (response: string) => {
  return {
    type: portalActionTypes.BACKUP_PORTAL_SUCCESS
  }
};

const portalBackupFail = (errResponse: string) => {
  return {
    type: portalActionTypes.BACKUP_PORTAL_FAIL,
  }
};

export const changeStatusPortal = (status: string, portalId: string, portalName: string) => {
  return dispatch => {
    if (status === "disable") {
      dispatch(deactivatePortalStart());
    } else if (status === 'delete'){
    } else {
      dispatch(activatePortalStart());
    }
    let method = "post";
    if(status === 'delete'){
      method = 'delete'
    }
    axiosInstance(`portal/${status}/${portalId}`, method, {}, {})
      .then(response => {
        let res = _.get(response, "data");
        if (status === "disable") {
          dispatch(deactivatePortalSuccess(res.message));
          Toast.fire({ icon: "success", titleText: portalName + " has been deactivated"});
        } else {
          dispatch(activatePortalSuccess(res.message));
          Toast.fire({ icon: "success", titleText: portalName + " was successfully activated" });
        }
        dispatch(get_portal_list());
      }).catch(err => {
        let errResponse = _.get(err, "response");
        if (status === "disable") {
          dispatch(deactivatePortalFail(errResponse.message));
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while deactivating " + portalName + "!",
            icon:"error"}
          );
        } else {
          dispatch(activatePortalFail(errResponse.message));
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while activating " + portalName + "!",
            icon:"error"}
          );
        }
      })
  }

};

export const changeStatusPortalBackup =  (portalId: string, portalName: string) => {
  return dispatch => {

    dispatch(portalBackupStart());
    let method = "post";

    axiosInstance(`portal/backup/${portalId}`, method, {}, {})
        .then(response => {
          let res = _.get(response, "data");
            dispatch(portalBackupSuccess(res.message));
            Toast.fire({ icon: "success", titleText: portalName + " was successfully backed up",  html: `File Name: ${res.fileName}`, timer: 5000} );
        }).catch(err => {
      let errResponse = _.get(err, "response");
      dispatch(portalBackupFail(errResponse.message));
        MySwal.fire({
          title:"Error!",
          titleText:"Error occurred while backing up " + portalName + "!",
          icon:"error"}
        );
    })
  }

}


const updatePortalStart = () => {
  return{
    type: portalActionTypes.UPDATE_PORTAL_START
  }
};

const updatePortalFail = (errors) => {
  return {
    type: portalActionTypes.UPDATE_PORTAL_FAIL,
    errors: errors,
  }
};

const updatePortalSuccess = () => {
  return {
    type: portalActionTypes.UPDATE_PORTAL_SUCCESS
  }

};

export const updatePortal = (portalSlug: string, portalName: string, portalComment: string, portalId: string, companyLogo: any, companyLogoSize:string, customScripts?: string, version?: string, accessibleAnnotations?:any) => {
  const data = new FormData();
  data.set("name", portalName);
  data.set("version", version);
  data.set("customScripts", customScripts);
  data.set("companyLogo", companyLogo);
  data.set("comments", portalComment);
  if(accessibleAnnotations){
    data.set("accessibleAnnotations", JSON.stringify(accessibleAnnotations));
  }
  if(companyLogoSize){
    data.set("companyLogoSize", companyLogoSize);
  }
  return async dispatch => {
    dispatch(updatePortalStart());
    axiosInstance(`${portalSlug}/portal/edit/${portalId}`, 'put',
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data
    ).then(response => {
      let res = _.get(response, "data");
      if (res.success) {
        MySwal.fire({
          title:"Updated!",
          titleText:portalName + " has been successfully updated.",
          icon:"success"}
        );
        dispatch(updatePortalSuccess());
        dispatch(getPortalSuccess(res.object));
        dispatch(changeSelectedPortal(res.object));
      }
    }).catch(err => {
      dispatch(updatePortalFail(err.response.data.errors));
    });
  }
};

export const getPortal = (id: string) => {
  return async (dispatch) => {
    dispatch(getPortalStart());
    axiosInstance(`portal/${id}`, 'get',
    {
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then(response => {
      let res: any = _.get(response, "data");
      if (res.success) {
        dispatch(getPortalSuccess(res.object));
      }
    }).catch(err => {
      dispatch(getPortalFail());
    });
  }
}


export const setPortalonReload = () => {
    return (dispatch: any) => {
        dispatch(detectPortalStart());
        const portalInfo = LocalStorageWrapped.getItem("portalInfo");
        if (portalInfo && !_.isEmpty(portalInfo)) {
            dispatch(detectPortalSuccess(portalInfo.portalName,
                                   true,
                                         portalInfo.portalType,
                                         portalInfo.portalId,
                                         portalInfo.portalSlug));
            return;
        } else {
            dispatch(detectPortalFail());
        }
    }
};
