import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Portal.css';

const Model: React.FC = () => {
    const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
    const [predictions, setPredictions] = useState<any>(null);
    const [apiPredictions, setApiPredictions] = useState<any>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (apiPredictions) {
            drawBoundingBoxes(apiPredictions);
        }
    }, [apiPredictions]);

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                setImageSrc(reader.result);
                await makeApiRequest(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const makeApiRequest = async (file: File) => {
        try {
            const image = await loadImageBase64(file);
            const response = await axios({
                method: "POST",
                url: "https://detect.roboflow.com/pole-detection-qqnax/31",
                params: {
                    api_key: "5MdqvUMzElCu2mIMMkzD"
                },
                data: image,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            });
            setApiPredictions(response.data.predictions);
            console.log(response.data);
        } catch (error) {
            console.error('Error making API request', error);
        }
    };

    const loadImageBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const drawBoundingBoxes = (predictions) => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (canvas && image) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, image.width, image.height);
                ctx.lineWidth = 2;
                ctx.strokeStyle = 'red';
                ctx.font = '18px Arial';
                predictions.forEach(pred => {
                    const x = pred.x - pred.width / 2;
                    const y = pred.y - pred.height / 2;
                    ctx.beginPath();
                    ctx.rect(x, y, pred.width, pred.height);
                    ctx.stroke();
                    ctx.fillStyle = 'red';
                    ctx.fillText(
                        `${pred.class} (${Math.round(pred.confidence * 100)}%)`,
                        x,
                        y > 10 ? y - 5 : 10
                    );
                });
            }
        }
    };

    return (
        <div className="Model">
            <h1>Electric Pole Detection</h1>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            <div style={{ position: 'relative' }}>
                {imageSrc && (
                    <canvas
                        ref={canvasRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 8,
                            pointerEvents: 'none'
                        }}
                    />
                )}
                {imageSrc && <img ref={imageRef} src={imageSrc as string} alt="Selected" style={{ maxWidth: '100%', display: 'block' }} />}
            </div>
            {predictions && <div>Predictions: {JSON.stringify(predictions)}</div>}
            {apiPredictions && (
                <div>
                    <h2>API Predictions:</h2>
                    {apiPredictions.map((pred: any, index: number) => (
                        <div key={index}>
                            <p>Class: {pred.class}</p>
                            <p>Confidence: {pred.confidence}</p>
                            <p>Bounding Box: {`x: ${pred.x}, y: ${pred.y}, width: ${pred.width}, height: ${pred.height}`}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Model;
