import React, {Component} from "react";
import {Button, Col, Form, Input, Label, Row} from "reactstrap";
import {UpdatePortalProps} from "../../interfaces/portal";
import {getPortal, updatePortal} from "../../store/actions/portal";
import {connect} from "react-redux";
import Spinner from "../UI/Spinner/Spinner";
import {Redirect} from "react-router";
import _ from "lodash";
import axios from "axios"
import {NavLink} from "react-router-dom";
import createHistory from 'history/createBrowserHistory'
import ErrorDisplayer from "../UI/ErrorHandling/ErrorDisplayer";
import {alwaysVisibleTools, annotationsTypeDropDown, datasetTypeDropDown} from "../../interfaces/dataset";

class UpdatePortal extends Component<UpdatePortalProps> {

  state = {
    portalName: {
      value: this.props.portalName || "",
      touched: false,
      valid: false
    },
    comments: {
          value: this.props.comments || "",
          touched: false,
          valid: false
    },
    customScripts: {
        value: this.props.customScripts || "",
        touched: false,
        valid: false
    },
    logo: {
      value: null,
      valid: false,
      touched: false
    },
    logoSize: {
      value: 'large',
      touched: false,
    },
    version: this.props.version,
    accessibleAnnotations: {},
    imagePreviewUrl: null,
    loadingPortal: false,
    errorObj: null,
    checkedDatasets:  this.props.accessibleAnnotations && Object.keys(this.props.accessibleAnnotations).length > 0
        ? this.props.accessibleAnnotations
        : annotationsTypeDropDown.reduce((acc, dataset) => {
            acc[dataset.id] = true;
            return acc;
        }, {}),
      changeCheckbox: false,
  }

  setPortalInState = () => {
    this.setState({
      portalName: {
        value: this.props.portalName || "",
        touched: false,
        valid: false
      },
      comments: {
            value: this.props.comments || "",
            touched: false,
            valid: false
      },
      customScripts: {
          value: this.props.customScripts || "",
          touched: false,
          valid: false
      },
      logo: {
        value: null,
        valid: false,
        touched: false
      },
      logoSize: {
        value: 'large',
        touched: false,
      },
      version: this.props.version,
      imagePreviewUrl: null,
      loadingPortal: false,
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    if (e.target.value.trim() === "") {
      let portalName = this.state.portalName;
      portalName.valid = false;
      this.setState({portalName: portalName});
    }
    const _portalDetails = { id: this.props.portalId,
                             name: this.state.portalName.value,
                             comments: this.state.comments.value,
                             image: this.state.logo.value,
                             image_size: this.state.logoSize.value,
                             customScripts: this.state.customScripts.value,
                             version: this.props.version,
                             accessibleAnnotations: this.state.checkedDatasets,
                            };
    // call api
      if(this.state.logo.touched){
          this.props.updatePortal(this.props.portalSlug, _portalDetails.name, _portalDetails.comments,
              _portalDetails.id, _portalDetails.image,_portalDetails.image_size,
              _portalDetails.customScripts, _portalDetails.version, _portalDetails.accessibleAnnotations);
      }else{
          this.props.updatePortal(this.props.portalSlug, _portalDetails.name, _portalDetails.comments,
              _portalDetails.id, '',_portalDetails.image_size,
              _portalDetails.customScripts, _portalDetails.version, _portalDetails.accessibleAnnotations);
      }

  };

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = () => {
      this.setState({
        logo: {value: file, valid: true, touched: true},
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file)
  }

  portalNameChangeHandler = (e) => {
    this.setState({
      portalName: {
        value: e.target.value,
        valid: e.target.value.trim() !== "",
        touched: true,
      }
    });
  };

  portalScriptChangeHandler = (e) => {
      this.setState({
          customScripts: {
              value: e.target.value,
              valid: true,
              touched: true,
          }
      });
  };

  portalCommentHandler = (e) => {
      this.setState({
          comments: {
              value: e.target.value,
              valid: true,
              touched: true,
          }
      });
  };

  componentDidMount(): void {
    if (!this.props.portalId) {
      this.props.loadPortal(_.get(this.props.match.params, "portalId"));
    } else {
      this.loadLogo();
      this.loadAllowedAnnotations();
      const history = createHistory();
      if (history.location.state) {
        history.replace({ ...history.location, state: {} });
      }
    }
  }

  loadLogo(): void {
    const imgSrc = process.env.REACT_APP_BACKEND_URL + "portal/logo/" + this.props.portalId;
    axios.get(imgSrc)
      .then(res => {
          if(res.data.success){
              const logo = this.state.logo;
              const logoSize = this.state.logoSize;
              logo.value = res.data.object.companyLogo;
              logoSize.value = res.data.object.companyLogoSize;
              this.setState({logo: logo});
              this.setState({logoSize: logoSize});
          }
      })
      .catch(err => {
      });
  }

    loadAllowedAnnotations = ()=> {
        const accessibleAnnotationsList = process.env.REACT_APP_BACKEND_URL + "portal/accessible-annotations/" + this.props.portalId;
        axios.get(accessibleAnnotationsList)
            .then(res => {
                if(res.data.success) {
                    this.setState({checkedDatasets: res.data.object})
                }else{
                    this.setState({checkedDatasets: annotationsTypeDropDown.reduce((acc, dataset) => {
                            acc[dataset.id] = true;
                            return acc;
                        }, {})}
                    )
                }
            })
            .catch(err => {
            });
    }

  isFormvalid = () => {
      return ( (this.state.portalName.valid && this.state.portalName.touched) ||
               (this.state.comments.valid && this.state.comments.touched) ||
               (this.state.customScripts.valid && this.state.customScripts.touched) ||
               (this.state.logo.valid && this.state.logo.touched) || (this.state.changeCheckbox) || (this.state.logoSize.touched)
      );
  };

    handleCheckboxChange = (datasetId: number) => {
        this.setState({changeCheckbox: true});
        this.setState(prevState => ({
            checkedDatasets: {
                // @ts-ignore
                ...prevState.checkedDatasets,
                // @ts-ignore
                [datasetId]: !prevState.checkedDatasets[datasetId]
            }
        }));
    };

    handleSizeChange = (event)=>{
        this.setState({
            logoSize: {value: event.target.value, touched: true}
        });
    }

  componentWillReceiveProps(nextProps) {
    if(this.props.selectedPortal !== nextProps.selectedPortal) {
      const _selectedPortal = nextProps.selectedPortal;
      this.setState({portalName: {value: _selectedPortal.name, valid: false, touched: false},
                           customScripts: {value: _selectedPortal.customScripts, touched: false, valid: false},
                           logo: { value: _selectedPortal.companyLogo, valid: false, touched: false},
                           version: _selectedPortal.version
                          });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success != this.props.success) {
      this.setPortalInState();
      this.loadLogo();
      this.loadAllowedAnnotations();
    }
  }

  render() {
    let content = null;
    let responseMsg = null;
    let imagePreview = null;
    let companyLogo = this.state.logo.value ?
      <img
        style={{maxHeight: "300px", maxWidth: "300px"}}
        alt={"logo"}
        src={this.state.logo.value}/> : null;
    if (this.state.imagePreviewUrl) {
      imagePreview = (<img
        style={{maxHeight: "300px", maxWidth: "300px"}}
        alt={"logo"} src={this.state.imagePreviewUrl}/>);
    } else {
      imagePreview = companyLogo
    }

    let form = (<>
      <div className="centerForm" style={{width: "700px"}}>
        <h1 className={"heading"}>Update Portal</h1>
        <Row>
          <Col className={"mx-auto"} sm={12}>
            <Label className="labelText">GUID</Label>
            <Input readOnly value={this.props.portalId}/>
            <p/>
          </Col>
          <Col className="mx-auto" sm={12}>
            <Label className="labelText">Name</Label>
            <Input
              className="inputItem"
              value={this.state.portalName.value}
              onChange={(event) => this.portalNameChangeHandler(event)}
            />
          <ErrorDisplayer errorObj={this.props.errors} keyName="name"/>
            {/* <p style={{color: "red"}}>{this.props.errors && this.props.errors.name}</p> */}
          </Col>
            <Col className="mx-auto" sm={12}>
                <Label className="labelText">Comment</Label>
                <Input
                    className="inputItem"
                    value={this.state.comments.value}
                    onChange={(event) => this.portalCommentHandler(event)}
                />
                {/* <ErrorDisplayer errorObj={this.props.errors} keyName="comments"/> */}
                <p/>
            </Col>
          {!this.props.isSuperUser? '':  
          <Col className="mx-auto" sm={12}>
              <Label className="labelText">Custom JS Script URL</Label>
              <Input className="inputItem"
                     value={this.state.customScripts.value}
                     onChange={(event) => this.portalScriptChangeHandler(event)}
              />
          </Col>
           } 
          <Col className="mx-auto" sm={12}>
            <Label className="labelText">Logo</Label>
            <Input
              type="file"
              accept="image/*"
              onChange={(event) => this.handleImageChange(event)}
            />
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '290px', marginBottom: '30px' }}>
                  <div style={{ marginRight: '5px' }}>
                      Large
                      <Input
                          type="radio"
                          name="logoSize"
                          value="large"
                          style={{ marginLeft: '5px' }}
                          checked={this.state.logoSize.value === 'large'}
                          onChange={(event) => this.handleSizeChange(event)} // Assuming you have a method to handle size change
                      />{' '}
                  </div>
                  <div style={{ marginLeft: '30px' }}>
                      Small
                      <Input
                          type="radio"
                          name="logoSize"
                          value="small"
                          checked={this.state.logoSize.value === 'small'}
                          style={{ marginLeft: '5px' }}
                          onChange={(event) => this.handleSizeChange(event)} // Assuming you have a method to handle size change
                      />{' '}
                  </div>
              </div>
            {imagePreview}
            <p style={{color: "green"}}>{responseMsg}</p>
          </Col>
            <Col className="mx-auto" sm={12}>
                <h4 className="labelText">Available Annotations</h4>
                <div style={{ paddingLeft: '20px' }}>
                    {annotationsTypeDropDown
                        .filter(annotation => !alwaysVisibleTools.has(annotation.id))
                        .map(annotation => (
                            <div key={annotation.id} className="checkbox-row">
                                <span className="checkbox-label">{annotation.name}</span>
                                <Input
                                    type="checkbox"
                                    checked={this.state.checkedDatasets[annotation.id]}
                                    onChange={() => this.handleCheckboxChange(annotation.id)}
                                />
                            </div>
                        ))
                    }
                </div>
                <p/>
            </Col>
            <Col>
            <Button
              onClick={(event) => this.submitHandler(event)}
              color="info"
              disabled={!this.isFormvalid()}
            >
              Update Portal
            </Button>
            <br/><br/>
            <Button className='cancel-portal-creation-btn' to={`/portals/`} color="danger" outline>
                <NavLink className='cancel-portal-anchor' to={`/portals/`} style={{ color: '#dc3545', width: '100%' }}>Cancel</NavLink>
            </Button>
          </Col>
        </Row>
      </div>
    </>);
    content = form;
    if (this.props.loading) {
      content = <Spinner/>
    }
    if (this.props.success) {
      content = form;
      responseMsg = "Successfully updated"
    }
    let redirect = null;
    if (!this.props.loading && this.props.portalId !== _.get(this.props.match.params, "portalId")) {
      redirect = <Redirect to={"/notfound"}/>
    }
    return (
      <>
        {redirect}
        {content}
      </>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  let portalData = {};
  if (!_.isEmpty(ownProps.location.state)) {
    portalData = {
      comments: ownProps.location.state?.comments || '',
      portalName: ownProps.location.state?.portalName || "",
      portalId: ownProps.location.state?.portalId || "",
      customScripts: ownProps.location.state?.customScripts || '',
      version: ownProps.location.state?.version || '',
    }
  } else {
    portalData = {
      loading: true,
      ...state.portal.updatingPortal
    };
  }
  return {
    loading: state.portalListReducer.updatePortalLoading,
    isSuperUser: state.login.loggedInUser.isSuperUser,
    success: state.portalListReducer.updateProjectSuccess,
    errors: state.portalListReducer.updatePortalErrors,
    ...portalData,
    portalSlug: state.portal?.updatingPortal?.portalSlug || ownProps?.location?.state?.portalSlug,
    selectedPortal: state.portalListReducer.selectedPortal
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadPortal: (portalId: string) => dispatch(getPortal(portalId)),
    updatePortal: (portalSlug: string, portalName: string, portalComment: string, portalId: string, companyLogo: any,companyLogoSize:string, customScripts: string, version: string,accessibleAnnotations: any) => dispatch(updatePortal(portalSlug, portalName,portalComment, portalId, companyLogo,companyLogoSize, customScripts, version, accessibleAnnotations))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePortal);
